import React, { Component } from "react";
import {
  GoogleMap,
  Marker,
  withScriptjs,
  withGoogleMap
} from "react-google-maps";

import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";

class GroupSittingsMap extends Component {
  onMarkerClick = location => {
    this.props.onMarkerClick(location);
  };
  onInfoWindowClose = () => {};

  setCenter = location => {
    this.ref.map.setCenter(this.getPositionFromLocation(location));
  };

  getPositionFromLocation = location => ({
    lat: Number(location.sub_location.latitude),
    lng: Number(location.sub_location.longitude)
  });

  renderMarker = location => {
    const position = this.getPositionFromLocation(location);
    const isSelected =
      this.props.selected && this.props.selected.id === location.id;

    return (
      <Marker
        id={location.id}
        key={location.id}
        position={position}
        onClick={() => this.onMarkerClick(location)}
        name={location.name}
        options={
          isSelected
            ? {
                icon: {
                  url: this.props.selectedPinIconUrl,
                  scaledSize: { width: 27, height: 43 }
                }
              }
            : { icon: undefined }
        }
      />
    );
  };

  componentDidMount() {
    if (this.props.mapRef) {
      this.props.mapRef(this.ref.map);
    }
  }

  render() {
    return (
      <GoogleMap
        options={{
          fullscreenControl: false,
          streetViewControl: false,
          zoomControl: true,
          mapTypeControlOptions: {
            mapTypeIds: ["map"]
          }
        }}
        ref={ref => {
          this.ref = ref;
        }}
        google={this.props.google}
        defaultZoom={3}
        center={
          this.props.center && {
            lat: Number(this.props.center.latitude),
            lng: Number(this.props.center.longitude)
          }
        }
        defaultCenter={
          this.props.center && {
            lat: Number(this.props.center.latitude),
            lng: Number(this.props.center.longitude)
          }
        }
      >
        {this.props.locations &&
          this.props.locations.map(location => this.renderMarker(location))}
      </GoogleMap>
    );
  }
}

export default withScriptjs(withGoogleMap(GroupSittingsMap));
