import React from "react";
import createReactClass from "create-react-class";

var LocationLinks = createReactClass({
  style: {
    base: {
      marginLeft: 0
    }
  },

  getInitialState() {
    return { showCourseLinks: false };
  },

  hasManySchedules() {
    return this.props.trans_schedule_urls.length > 1;
  },

  toggleShowCourseLinks() {
    this.setState({ showCourseLinks: !this.state.showCourseLinks });
  },

  renderCoursesDropDown() {
    if (this.state.showCourseLinks && this.hasManySchedules()) {
      let links = this.props.trans_schedule_urls.map(url => {
        return (
          <li key={url.schedule_url}>
            <a target="_blank" rel="nofollow" href={url.schedule_url}>
              {url.description}
            </a>
          </li>
        );
      });
      return (
        <ul
          style={{ display: "block", top: "auto", left: "auto" }}
          className="dropdown-menu open"
        >
          {links}
        </ul>
      );
    }
  },

  onCourseClick(e) {
    e.stopPropagation();
    if (this.hasManySchedules()) {
      this.toggleShowCourseLinks();
    } else {
      window.open(this.props.schedule_url);
    }
  },

  renderCourseLinks() {
    return (
      <span>
        <i className="glyphicon glyphicon-calendar" />
        &nbsp;
        <a onClick={this.onCourseClick}>{this.props.phrases.courses}</a>
        &nbsp;&#183;&nbsp;
        {this.state.showCourseLinks ? this.renderCoursesDropDown() : null}
      </span>
    );
  },

  render() {
    return (
      <span style={this.style.base} className="links">
        {this.renderCourseLinks()}
        <i className="glyphicon glyphicon-home" />
        &nbsp;
        <a href={this.props.website_url} target="_blank">
          {this.props.phrases.website}
        </a>
        &nbsp;&#183;&nbsp;
        <i className="glyphicon glyphicon-map-marker" />
        &nbsp;
        <a href={this.props.map_url} target="_blank">
          {this.props.phrases.map}
        </a>
      </span>
    );
  }
});

var LocationRow = createReactClass({
  style: {
    base: {
      paddingLeft: 55
    },

    dist: {
      color: "#999",
      marginBottom: 6
    },

    flag: {
      position: "absolute",
      top: 16,
      left: 12
    },

    details: {
      marginTop: 10
    },

    image: {
      width: "100%"
    }
  },

  renderImage() {
    if (this.props.has_header_image) {
      return (
        <img style={this.style.image} src={this.props.header_image_path} />
      );
    }
  },

  renderDetails() {
    return (
      <div style={this.style.details}>
        {this.renderImage()}
        <p dangerouslySetInnerHTML={{ __html: this.props.contact }} />
        <p dangerouslySetInnerHTML={{ __html: this.props.about }} />
      </div>
    );
  },

  renderDisplayName() {
    let dhammaName = this.props.dhammaName,
      displayName = this.props.city_province;

    if (dhammaName === displayName) {
      return <span className="display-name">{displayName}</span>;
    } else {
      return (
        <span className="display-name">
          {displayName} &#183;{" "}
          <span className="dhamma-name">{this.props.dhamma_name}</span>
        </span>
      );
    }
  },

  render() {
    const { selected, showCenterLinks } = this.props;

    const details = selected && this.renderDetails();

    return (
      <div
        style={this.style.base}
        onClick={this.props.onSelect}
        className="location"
      >
        <div className="body">
          <div style={this.style.flag} className={this.props.flag_icon_class} />
          <h3 className="name">
            {this.renderDisplayName()}
            &nbsp;
            <span className={this.props.pin_class} />
          </h3>
          {showCenterLinks && <LocationLinks {...this.props} />}
          <h4 style={this.style.dist}>
            {Math.round(this.props.haversine_distance)}{" "}
            {this.props.phrases.uom[this.props.uom]}
          </h4>
        </div>
        {details}
      </div>
    );
  }
});

var ClosestLocations = createReactClass({
  getInitialState() {
    return {
      selectedLocation: null
    };
  },

  onSelect(subDomain) {
    if (this.state.selectedLocation === subDomain) {
      this.setState({ selectedLocation: null });
    } else {
      this.setState({ selectedLocation: subDomain });
    }
  },

  renderLocations() {
    const { phrases, uom, showCenterLinks } = this.props;

    return this.props.locations.map(l => {
      let selected = l.sub_domain === this.state.selectedLocation;
      return (
        <LocationRow
          selected={selected}
          key={l.sub_domain}
          {...l}
          phrases={phrases}
          uom={uom}
          showCenterLinks={showCenterLinks}
          onSelect={this.onSelect.bind(this, l.sub_domain)}
        />
      );
    });
  },

  render() {
    const { phrases } = this.props;

    return (
      <div>
        {this.renderLocations()}
        <br />
        <div id="closest-list-disclaimer">
          *{phrases.closestLocationsDescription}
        </div>
      </div>
    );
  }
});

export default ClosestLocations;
