import React from "react";
import haversine from "haversine";
import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";
import cn from "classnames";
import Tooltip from "react-tooltip";

import Map from "./map";
import EventLocationRow from "./row";
import styles from "./styles";

const listRef = React.createRef();

var DEFAULT_CENTER = {
  latitude: 19.703254,
  longitude: 73.554282
};

var SUBDOMAINS_BLOCKED_FOR_EMBEDDING = ['www', 'test', 'staging'];

class ClosestEvents extends React.PureComponent {
  styles = {
    alert: {
      margin: "100px 10px",
      fontWeight: "bold",
      color: "#666",
      textAlign: "center"
    }
  };

  state = {
    blocked: !this.isEmbeddingAllowed(),
    loading: false,
    loaded: false,
    locations: null,
    fetchingLatLong: true,
    locationServicesEnabled: null,
    selectedEventType: this.props.eventTypes.filter(
      type => type.value === "GroupSitting"
    )[0],
    expanded: null
  };

  onLocationServicesDisabled() {
    return this.setState({
      locationServicesEnabled: false
    });
  }

  inIFrame() {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
  }

  subdomain() {
    const url = (window.location != window.parent.location)
            ? document.referrer
            : document.location.href;

    const matches = url.match(new RegExp("https?://([a-z0-9.]+)[.]dhamma[.]org"));

    if (matches && !SUBDOMAINS_BLOCKED_FOR_EMBEDDING.includes(matches[1])) {
      return matches[1];
    }

    return null;
  }

  isEmbeddingAllowed() {
    // If not in iFrame, allow
    if (!this.inIFrame()) {
      return true;
    }

    // If not a dhamma.org subdomain, block
    if (!this.subdomain()) {
      return false;
    }

    return true;
  }

  async componentDidMount() {
    if (this.state.blocked) {
      return false;
    }

    await this.fetchLocations();

    if (this.subdomain()) {
      try {
        const coords = await this.fetchSubdomainCoords();

        if (coords.latitude && coords.longitude) {
          return this.setState({
            fetchingLatLong: false,
            locationServicesEnabled: false,
            coords,
            locations: this.getLocationsSortedByDistance(
              this.state.locations,
              coords
            )
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    this.timeout = setTimeout(() => this.onLocationServicesDisabled(), 10000);

    navigator.geolocation.getCurrentPosition(
      position => {
        clearTimeout(this.timeout);
        this.setState({
          fetchingLatLong: false,
          locationServicesEnabled: true,
          coords: position.coords,
          locations: this.getLocationsSortedByDistance(
            this.state.locations,
            position.coords
          )
        });
      },
      () => {
        clearTimeout(this.timeout);
        this.onLocationServicesDisabled();
      }
    );
  }

  fetchSubdomainCoords() {
    return $.getJSON(`/${this.props.locale}/api/v1/locations/${this.subdomain()}/coordinates`);
  }

  fetchLocations() {
    this.setState({ loading: true, loaded: false });

    return $.getJSON(`/${this.props.locale}/api/v1/events/active`).done(
      result => {
        this.setState({
          locations: this.getLocationsSortedByDistance(result, DEFAULT_CENTER),
          loaded: true,
          loading: false
        });
      }
    );
  }

  getLocationsSortedByDistance(locations, coords) {
    return this.getLocationsWithDistanceToCoords(locations, coords).sort(
      (a, b) => {
        return a.haversine_distance - b.haversine_distance;
      }
    );
  }

  getLocationsWithDistanceToCoords(locations, coords) {
    return locations.map(location => ({
      ...location,
      haversine_distance: haversine(
        {
          latitude: Number(coords.latitude),
          longitude: Number(coords.longitude)
        },
        {
          latitude: Number(location.sub_location.latitude),
          longitude: Number(location.sub_location.longitude)
        },
        {
          unit: {
            miles: "mile",
            kilometers: "km"
          }[this.props.uom]
        }
      ),
      uom: this.props.uom
    }));
  }

  getFilteredLocations() {
    if (!this.state.locations) return null;
    if (!this.state.selectedEventType) return this.state.locations;

    return this.state.locations.filter(
      loc => loc.event_type_code === this.state.selectedEventType.value
    );
  }

  shouldRenderMap() {
    return !this.state.loading && this.state.loaded;
  }

  renderLocationRow = ({ index, style }) => {};

  renderLocations = locations => {
    const { phrases, uom, showCenterLinks, fallbackImage } = this.props;

    if (!this.state.loading && this.state.loaded) {
      if (locations.length === 0) {
        return <p style={this.styles.alert}>{phrases.noneFound}</p>;
      }

      return (
        <AutoSizer key={this.state.selectedEventType}>
          {({ width, height }) => (
            <List
              ref={listRef}
              width={width}
              height={height}
              itemCount={locations.length}
              itemSize={index =>
                locations[index].sub_location.contact_phone ? 120 : 98
              }
            >
              {({ index, style }) => {
                const loc = locations[index];

                return (
                  <div style={style}>
                    <EventLocationRow
                      {...loc}
                      key={loc.id}
                      onClick={() => this.setState({ highlighted: loc })}
                      onShowMore={() => this.handleLocationToggle(loc)}
                      highlighted={
                        this.state.highlighted &&
                        this.state.highlighted.id === loc.id
                      }
                      phrases={phrases}
                      uom={uom}
                      fallbackImage={fallbackImage}
                      showDistance={this.state.locationServicesEnabled}
                      isLast={locations.length === index + 1}
                    />
                  </div>
                );
              }}
            </List>
          )}
        </AutoSizer>
      );
    }
  };

  handleLocationToggle = loc => {
    if (this.state.expanded && this.state.expanded.id === loc.id) return;

    this.setState({ expanded: loc, highlighted: loc });
  };

  scrollToLocation = loc => {
    const index = this.getFilteredLocations()
      .map(location => location.id)
      .indexOf(loc.id);
    listRef.current.scrollToItem(index, "start");
  };

  getCurrentLocationOption() {
    return {
      label: "Current location",
      value: 0,
      latitude: this.state.coords.latitude,
      longitude: this.state.coords.longitude
    };
  }

  getCurrentMapCenter() {
    const center = this.state.highlighted || this.state.coords;

    if (!center) return DEFAULT_CENTER;

    return center.sub_location ? center.sub_location : center;
  }

  getOptions() {
    const regions = this.props.autocompleteJson.results[0].children;
    const locations = this.props.autocompleteJson.results[1].children;

    const options = [
      {
        label: "Regions",
        options: regions.map(region => ({
          label: region.text,
          value: Number(region.id),
          ...region
        }))
      },
      {
        label: "Locations",
        options: locations.map(loc => ({
          label: loc.text,
          value: Number(loc.id),
          ...loc
        }))
      }
    ];

    if (this.state.locationServicesEnabled) {
      return [this.getCurrentLocationOption(), ...options];
    } else {
      return options;
    }
  }

  handleEventTypeSelect(value) {
    this.setState({ selectedEventType: value }, () => {
      listRef.current.resetAfterIndex(1, true);
      const locationsWithDistance = this.getLocationsWithDistanceToCoords(
        this.getFilteredLocations(),
        this.getCurrentMapCenter()
      );

      const closest = locationsWithDistance.reduce(
        (a, b) => (a.haversine_distance <= b.haversine_distance ? a : b),
        {}
      );

      this.scrollToLocation(closest);
    });
  }

  handleMapMarkerClick = loc => {
    this.setState({ highlighted: loc }, () => {
      this.scrollToLocation(loc);
    });
  };

  toggleHelpSection = () => {
    Tooltip.show();
  };

  render() {
    if (this.state.blocked) {
      return null;
    }

    const locations = this.getFilteredLocations();

    if (
      this.state.locationServicesEnabled !== false &&
      this.state.fetchingLatLong
    ) {
      return (
        <p style={this.styles.alert} key="fetchingLocations">
          {this.props.phrases.fetchingLocations}
        </p>
      );
    }

    return (
      <div className={styles.container}>
        <Tooltip
          className={styles.tooltip}
          place="left"
          type="info"
          effect="solid"
          multiline
          globalEventOff="touchstart"
        />
        <div className={cn(styles.helpSection)}>
          <div
            onClick={this.toggleHelpSection}
            className={styles.helpSectionIcon}
            data-tip={this.props.phrases.helpText}
            data-event="touchstart focus mouseover"
            data-event-off="mouseout"
          >
            <i
              className={`glyphicon glyphicon-question-sign`}
            />
          </div>
        </div>
        <div
          className={cn(styles.modal, {
            [styles.expanded]: this.state.expanded
          })}
        >
          {this.state.expanded && (
            <div>
              <a href="#" onClick={() => this.setState({ expanded: null })}>
                {`← ${this.props.phrases.back}`}
              </a>
              <EventLocationRow
                {...this.state.expanded}
                expanded
                phrases={this.props.phrases}
                uom={this.props.uom}
                fallbackImage={this.props.fallbackImage}
                showDistance={this.state.locationServicesEnabled}
              />
            </div>
          )}
        </div>
        <div className={styles.mapContainer}>
          {this.shouldRenderMap() && (
            <Map
              googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDdkYy7D2royIjZ2xUj7lJnyrec5E-b7yQ&v=3.exp&libraries="
              loadingElement={<div />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
              key={this.state.loading}
              center={this.getCurrentMapCenter()}
              locations={locations}
              onMarkerClick={this.handleMapMarkerClick}
              selected={this.state.highlighted}
              selectedPinIconUrl={this.props.selectedPinIconUrl}
            />
          )}
        </div>
        <div className={`nav nav-pills ${styles.tabs}`}>
          {this.props.eventTypes.map(eventType => (
            <li
              role="presentation"
              className={
                eventType.value === this.state.selectedEventType.value &&
                "active"
              }
            >
              <a
                href="##"
                onClick={() => this.handleEventTypeSelect(eventType)}
              >
                {eventType.label}
              </a>
            </li>
          ))}
        </div>
        <div className={styles.list}>{this.renderLocations(locations)}</div>
      </div>
    );
  }
}

export default ClosestEvents;
