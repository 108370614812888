import React from "react";
import createReactClass from "create-react-class";
import EventLocationLinks from "./links";
import cn from "classnames";
import styles from "./styles";

const EventLocationRow = createReactClass({
  style: {
    body: {
      marginLeft: 50,
      fontSize: 13
    },

    dist: {
      color: "#666",
      marginBottom: 6
    },

    flag: {
      position: "absolute",
      top: 16,
      left: 12
    },

    details: {
      marginTop: 15,
      marginTop: 10,
      fontSize: 13,
      lineHeight: "22px"
    },

    description: {
      fontSize: 13,
      lineHeight: "22px"
    },

    image: {
      width: 35,
      height: 35,
      border: "none",
      marginTop: 20,
      float: "left"
    },

    title: {
      color: "black",
      fontSize: 14,
      marginBottom: 6,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },

    subtitle: {
      color: "#666",
      marginTop: 0,
      fontSize: 13,
      marginBottom: 3,
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },

    address: {
      fontSize: 13,
      lineHeight: "22px"
    }
  },

  getInitialState() {
    return {
      selected: false
    };
  },

  renderImage() {
    return (
      <div
        style={this.style.image}
        className={this.props.sub_location.flag_icon_class}
      />
    );
  },

  renderDetails() {
    return (
      <div style={this.style.details}>
        <p
          style={this.style.description}
          dangerouslySetInnerHTML={{ __html: this.props.description }}
        />
        <h4>Address</h4>
        <div style={this.style.address}>
          {this.props.sub_location.street_address}
        </div>

        {this.props.sub_location.contact_email && (
          <div>
            <i className="glyphicon glyphicon-envelope" />
            &nbsp;
            <a
              style={this.style.link}
              href={`mailto:${this.props.sub_location.contact_email}`}
              target="_blank"
            >
              {this.props.sub_location.contact_email}
            </a>
          </div>
        )}
      </div>
    );
  },

  renderDisplayName() {
    return;
  },

  render() {
    const { isLast } = this.props;

    const details = this.props.expanded && this.renderDetails();

    return (
      <div
        className={cn(styles.row, {
          [styles.last]: isLast,
          [styles.highlighted]: this.props.highlighted
        })}
        onClick={this.props.onClick}
      >
        {this.renderImage()}
        <div style={this.style.body} className="body">
          <h3 style={this.style.title}>
            <span className="display-name">
              {this.props.event_type} &#183;{" "}
              {this.props.sub_location.city_province}
            </span>
          </h3>
          <h4 style={this.style.subtitle}>
            {this.props.showDistance &&
              `${Math.round(this.props.haversine_distance)} ${
                this.props.phrases.uom[this.props.uom]
              } · `}
            {this.props.schedule}
          </h4>
          <EventLocationLinks {...this.props} />
          {details}
        </div>
      </div>
    );
  }
});

export default EventLocationRow;
