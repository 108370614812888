import React from "react";
import createReactClass from "create-react-class";

const interleave = (array, el) => {
  const newArray = [];

  array.forEach((item, i) => {
    if (array.length - 1 === i) {
      newArray.push(item);
    } else {
      newArray.push(item, el);
    }
  });

  return newArray;
}

const collapsed = [
  'Directions',
  'Add to Calendar',
  'Details',
]

const expanded = [
  'Directions',
  'Add to Calendar',
  'Website',
]

const EventLocationLinks = createReactClass({
  style: {
    base: {
      marginLeft: 0,
      color: "#666",
      whiteSpace: "nowrap"
    },
    link: {
      color: "#666"
    }
  },

  render() {
    const details = (
      <span key={`${this.props.id}-details`}>
        <i className="glyphicon glyphicon-info-sign" />
        &nbsp;
        <a
          style={this.style.link}
          href="##"
          onClick={this.props.onShowMore}
        >
          {this.props.phrases.details}
        </a>
      </span>
    );

    const phone = (
      <div>
        <i className="glyphicon glyphicon-earphone" />
        &nbsp;
        <a
          style={this.style.link}
          href={`tel:${this.props.sub_location.contact_phone}`}
          target="_blank"
        >
          {this.props.sub_location.contact_phone}
        </a>
      </div>
    );

    const website = (
      <span key={`${this.props.id}-website`}>
        <i className="glyphicon glyphicon-home" />
        &nbsp;
        <a style={this.style.link} href={this.props.url} target="_blank">
          {this.props.phrases.website}
        </a>
      </span>
    );

    const directions = (
      <span key={`${this.props.id}-directions`}>
        <i className="glyphicon glyphicon-map-marker" />
        &nbsp;
        <a
        style={this.style.link}
        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
          this.props.sub_location.street_address
        )}`}
        target="_blank"
        >
          {this.props.phrases.directions}
        </a>
      </span>
    );

    const calendar = (
      <span key={`${this.props.id}-calendar`}>
        <i className="glyphicon glyphicon-calendar" />
        &nbsp;
        <a
        style={this.style.link}
        href={`https://calendar.google.com/calendar/r?cid=${encodeURIComponent(
          this.props.ics_url
        )}`}
        target="_blank"
        >
        {this.props.phrases.addToCalendar}
        </a>
      </span>
    );

    const separator = <span>&nbsp;&#183;&nbsp;</span>;

    let links;

    if (this.props.expanded) {
      links = [directions, this.props.ics_url && calendar, website];
    } else {
      links = [details, directions, this.props.ics_url && calendar];
    }

    const activeLinks = links.filter(link => link);

    return (
      <span style={this.style.base} className="links">
        {this.props.sub_location.contact_phone && (phone)}
        <div>
          {interleave(activeLinks, separator)}
        </div>
      </span>
    );
  }
});

export default EventLocationLinks;
